<template>
  <div class="home">
    <Hero />
    <Steps />
    <AppPrizes />
    <AppWinners />
    <AppFaq />
    <AppStories />
    <Footer />
  </div>
</template>

<script>
import AppFaq from '../components/AppFaq.vue';
import AppWinners from '../components/AppWinners.vue';
import AppStories from "../components/AppStories.vue";
import AppPrizes from "../components/AppPrizes.vue";
import Hero from "@/components/AppHero.vue";
import Steps from "@/components/AppSteps.vue";
import Footer from "@/components/AppFooter.vue";
export default {
  data: function () {
    let self = this;
    let delay = 1000; //milliseconds
    let timeoutId;
    let animationIsFinished = false;
    return {
      publicPath: process.env.BASE_URL,
      currentSection: null,
      parameters: {
        event: "gaEvent",
        category: "Check_reg_main_form_CB",
        action: "Check_reg_main_form_CB",
        label: "main",
      },
    };
  },

  mounted() {
    // this.$modal.show("success_feedback", { mail: 'anton.smirnov@yandex.ru '});
    this.$parent.showFooter = false;
    const $this = this;
    const sections = document.querySelectorAll(".section");
    this.$modal.show("temporarily_unavailable");
    window.addEventListener("scroll", () => {
      let scrollPosition = window.scrollY + 80;
      sections.forEach((section) => {
        if (scrollPosition >= section.offsetTop) {
          section.classList.add("active");
        }
      });
      if (window.scrollY > 100) {
        document.querySelector(".header").classList.add("_modified");
      } else {
        document.querySelector(".header").classList.remove("_modified");
      }
    });
  },
  watch: {
    currentSection: function (val) {
      console.log("val" + val);
    },
  },
  methods: {
    showModal(name) {
      this.$modal.show(name);
    },
  },

  components: {
    Hero,
    Steps,
    Footer,
    AppPrizes,
    AppStories, AppWinners, AppFaq
  },
};
</script>

<style lang="scss">
.home {
  .footer {
    margin-top: 0;
  }
}
</style>
