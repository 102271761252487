<template>
  <div class="section winners" id="winners">
    <div class="container">
      <div class="winners__header">
        <h2 class="section-title">Победители</h2>
      </div>
      <ul class="winners-nav hidden-xs">
        <li
          v-for="(nav, i) in dates"
          :key="i"
          :class="{ active: activeDate === i }"
          @click="(activeDate = i), dateNavigation(nav.value)"
          class="winners-nav__tab"
        >
          {{ nav.label }}
        </li>
      </ul>
      <Select
        :options="dates"
        :field="dates[0]"
        @input="date = $event.value"
        @reduce="(label) => label.value"
        class="winners-select visible-xs"
      />
      <div class="winners-content">
        <div class="winners-elements">
          <div class="winners-elements__item">
            <svg
              width="46"
              height="46"
              viewBox="0 0 46 46"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M27.1243 45.3531L16.5821 32.484L0.760225 27.3341L13.6294 16.7918L18.781 0.973256L29.3234 13.8424L45.14 18.9907L32.2709 29.533L27.1243 45.3531Z"
                fill="#FFE100"
              />
            </svg>
          </div>
          <div class="winners-elements__item">
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19.6015 0.776241L22.9219 11.898L31.7189 19.4741L20.5973 22.7923L13.0224 31.5848L9.70186 20.463L0.907796 12.8893L12.0294 9.57116L19.6015 0.776241Z"
                fill="#71C1DF"
              />
            </svg>
          </div>
          <div class="winners-elements__item _no-animate">
            <svg
              width="35"
              height="35"
              viewBox="0 0 35 35"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M34.1629 8.4125C34.8727 12.9057 33.4289 18.1875 31.417 22.9461C29.4033 27.706 26.8196 31.944 25.24 34.3481C25.159 34.4707 25.04 34.5537 24.9094 34.5946C24.7769 34.6368 24.6329 34.6369 24.4948 34.5843C19.1455 32.4814 5.42606 27.6278 1.38187 19.4732C0.38433 17.464 -0.0145532 15.4919 0.197544 13.6154L0.204395 13.5566C0.592751 9.81613 2.3929 7.14302 6.00912 5.50707C9.29272 4.02015 14.0991 4.09435 17.3263 7.65764C17.9653 2.96443 20.0861 1.32404 23.5298 0.415863C25.7698 -0.177212 29.2608 0.11174 31.7262 2.89197L31.7533 2.92828C33.3023 4.65354 33.9181 6.85962 34.1629 8.4125Z"
                fill="#D00015"
              />
            </svg>
          </div>
          <div class="winners-elements__item">
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.9"
                d="M0.354605 4.03071C1.20326 1.11284 4.25586 -0.563297 7.17103 0.283674C10.0885 1.13131 11.7665 4.18185 10.9178 7.09973C10.0691 10.0176 7.01649 11.6936 4.09901 10.846C1.18384 9.99903 -0.494049 6.94858 0.354605 4.03071Z"
                fill="#61BADB"
              />
            </svg>
          </div>
          <div class="winners-elements__item">
            <svg
              width="7"
              height="7"
              viewBox="0 0 7 7"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.9"
                d="M1.0528 2.63016C1.5157 1.0386 3.18075 0.124344 4.77085 0.586328C6.3622 1.04868 7.27744 2.71261 6.81454 4.30418C6.35164 5.89574 4.68655 6.80994 3.0952 6.3476C1.5051 5.88561 0.589894 4.22173 1.0528 2.63016Z"
                fill="#EFA800"
              />
            </svg>
          </div>
        </div>
        <Sun />
        <ul v-if="winners && winners.length" class="winners-list">
          <li
            v-for="(item, i) in winnersSliced"
            :key="i"
            class="winners-list__item"
          >
            <div class="winners-list__item-index">{{ i + 1 }}</div>
            <div class="winners-list__item-block">
              <div class="winners-list__item-name">{{ item.name }}</div>
              <div class="winners-list__item-bottom">
                <span>{{ item.email }}</span> | <span>{{ item.prize }}</span>
              </div>
            </div>
          </li>
        </ul>
        <div v-else class="winners-empty">Скоро тут будут победители!</div>
        <template v-if="winners && winners.length">
          <div
            v-if="winners.length > limit"
            class="winners-show-more visible-xs"
            @click="showMore"
          >
            <span>Еще {{ winners.length - limit }} победителей</span>
            <svg
              width="21"
              height="20"
              viewBox="0 0 21 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.17969 8L10.1797 13L15.1797 8"
                stroke="#3F3F3F"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <router-link
            v-else
            :to="{ name: 'Home', hash: '#winners' }"
            class="winners-show-more visible-xs"
            @click.native="showMore"
          >
            <span>Скрыть</span>
          </router-link>
        </template>
        
      </div>
    </div>
  </div>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";
import Sun from "../components/AppSun.vue";
import Select from "../components/form/inputSelect.vue";
export default {
  components: { Sun, Select },
  data: function () {
    return {
      page: 1,
      perPage: 1,
      pages: [],
      searchQuery: null,
      date: null,
      total: 0,
      noResults: false,
      errorMessage: {
        date: null,
        searchQuery: null,
      },

      winners: [],
      winnersSliced: [],
      limit: 5,
      activeDate: 0,
      dates: [
        {
          label: "1-7 мая",
          value: "01.05.2024",
        },
        {
          label: "8-14 мая",
          value: "08.05.2024",
        },
        {
          label: "15-21 мая",
          value: "15.05.2024",
        },
        {
          label: "22-28 мая",
          value: "22.05.2024",
        },
        {
          label: "29-4 июня",
          value: "29.05.2024",
        },
        {
          label: "5-11 июня",
          value: "05.06.2024",
        },
        {
          label: "12-18 июня",
          value: "12.06.2024",
        },
        {
          label: "19-25 июня",
          value: "19.06.2024",
        },
        {
          label: "26-30 июня",
          value: "26.06.2024",
        },
      ],
    };
  },
  validations: {
    searchQuery: { required, email },
  },
  methods: {
    errorReset() {
      this.$v.$reset();

      this.errorMessage = {
        searchQuery: null,
      };
    },
    validationStatus: function (validation) {
      return typeof validation != "undefined" ? validation.$error : false;
    },
    dateNavigation(i) {
      this.$store.dispatch("GetWinners", { date: i }).then((response) => {
        this.winners = response.data.items;
      });
    },
    showMore() {
      if (this.winners.length > this.limit) {
        this.limit = this.winners.length;
        this.winnersSliced = this.winners;
      } else {
        this.limit = 5;
        this.winnersSliced = this.winners.slice(0, this.limit);
      }
    },
  },
  mounted() {
   
  },
  created() {
    this.dateNavigation(this.dates[0].value);
  },
  watch: {
    date(i) {
      this.dateNavigation(i);
    },
    winners() {
      if (window.innerWidth > 900) {
        this.winnersSliced = this.winners;
      } else {
        this.winnersSliced = this.winners.slice(0, this.limit);
      }
    }

  },
  computed: {
    // displayedWinners() {
    //   return this.paginate(this.winners);
    // },
  },
};
</script>

<style lang="scss">
.winners {
  position: relative;
  display: flex;
  align-items: center;
  z-index: 1;
  background-size: cover;
  &-content {
    position: relative;
    padding: rem(30px) rem(25px);
    background: #fff;
    border-radius: rem(35px);
  }
  &-select {
    z-index: 10;
  }
  &-elements {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    pointer-events: none;
    &__item {
      position: absolute;
      display: block;
      transform: scale(0.7);
      animation: rotareStar 4s linear infinite forwards normal;
      svg {
        animation: blinkSmooth 2s infinite forwards alternate;
      }
      &._no-animate {
        animation: none;
      }
      &:nth-child(1) {
        display: none;
        @media (min-width: $md) {
          display: block;
          top: 105%;
          left: 58%;
        }
      }
      &:nth-child(2) {
        top: rem(150px);
        left: 80%;
        @media (min-width: $md) {
          top: 55%;
          left: 90%;
        }
      }
      &:nth-child(3) {
        top: rem(50px);
        left: 10%;
        @media (min-width: $md) {
          top: 72%;
          left: 62%;
        }
      }
      &:nth-child(4) {
        display: none;
        @media (min-width: $md) {
          display: block;
          top: 101%;
          left: 52%;
        }
      }
      &:nth-child(5) {
        top: rem(50px);
        left: 80%;
        @media (min-width: $md) {
          top: 70%;
          left: 93%;
        }
      }
      
      &:nth-child(2n) {
        animation-delay: 0.5s;
        svg {
          animation-delay: 0.5s;
        }
      }
      &:nth-child(3n) {
        animation-delay: 0.7s;
        svg {
          animation-delay: 0.7s;
        }
      }
      &:nth-child(4n) {
        animation-delay: 1s;
        svg {
          animation-delay: 1s;
        }
      }
    }
  }
  &-empty {
    display: flex;
    align-items: center;
    justify-content: center;
    height: rem(300px);
    text-align: center;
  }
  &-list {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    align-content: flex-start;
    &__item {
      display: flex;
      padding-bottom: rem(14px);
      padding-right: rem(24px);
      margin-bottom: rem(20px);
      border-bottom: 1px solid rgba(63, 63, 63, 0.15);
      &-index {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: rem(7px);
        @include size(rem(25px));
        color: #fff;
        font-size: rem(10px);
        background: url("data:image/svg+xml,%3Csvg width='25' height='23' viewBox='0 0 25 23' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='0.9' d='M10.7879 1.08113C11.6091 0.909496 12.4364 0.835938 13.2576 0.835938H13.5664L13.8627 0.860457C14.0665 0.872717 14.2641 0.891106 14.4678 0.921755C14.8753 0.958534 15.2705 1.03209 15.6657 1.12404C17.2278 1.49183 18.7035 2.20902 19.9569 3.25722C22.4699 5.30459 24.0752 8.56567 24.0938 11.9003V12.2191C24.0938 12.3294 24.0814 12.4397 24.0752 12.5439C24.0629 12.7585 24.0382 12.973 24.0135 13.1876C23.9517 13.6167 23.8529 14.0212 23.7295 14.4258C23.4825 15.2349 23.112 15.995 22.6551 16.7061C21.7413 18.116 20.4817 19.299 19.0986 20.1265C16.3201 21.8858 13.06 22.2107 10.226 22.7133C9.86787 22.7746 9.50975 22.8053 9.16398 22.8237L8.65768 22.8359H8.39836L8.15138 22.8175C7.47219 22.7808 6.83622 22.6704 6.22496 22.4743C4.99007 22.1065 3.87867 21.4077 2.92781 20.3717C1.98312 19.3664 1.19897 18.0118 0.705018 16.4793C0.458041 15.7192 0.285157 14.9039 0.186366 14.0764C0.136971 13.6596 0.106099 13.2366 0.09375 12.8136V12.1761C0.09375 12.0658 0.09375 11.9555 0.106099 11.8513C0.106099 11.7471 0.106099 11.6367 0.118448 11.5325C0.334553 7.9159 1.37186 5.55592 3.13774 4.02346C4.92833 2.46035 7.50924 1.81671 10.794 1.08113H10.7879Z' fill='%23FF3F00'/%3E%3C/svg%3E%0A")
          no-repeat center;
        background-size: contain;
      }
      &-name {
        font-size: rem(18px);
      }
      &-bottom {
        color: rgba(63, 63, 63, 0.6);
        font-family: $font-light;
        font-size: rem(12px);
        line-height: 1;
      }
    }
  }
  &-show-more {
    display: flex;
    justify-content: center;
    align-items: center;
    height: rem(52px);
    padding: 0 rem(18px) 0 0;
    border: rem(2px) solid $grey;
    border-radius: rem(30px);
    font-size: rem(18px);
    span {
      display: block;
      margin: auto;
    }
  }
  .sun {
    width: rem(192px);
    margin: 0 auto rem(30px);
  }
  @media (min-width: $sm) {
    &-tab-desktop {
      display: block;
    }
    &-tab-mobile {
      display: none;
    }
  }
  @media (min-width: $md) {
    padding: rem(100px) 0;
    &-content {
      padding: rem(60px) rem(103px);
      border-radius: rem(50px);
    }
    &-empty {
      font-size: rem(20px);
    }
    .sun {
      position: absolute;
      bottom: rem(-150px);
      right: rem(74px);
      width: rem(400px);
      margin: 0;
    }
    &-nav {
      display: flex;
      align-items: center;
      gap: 0 rem(10px);
      margin-bottom: rem(30px);
      &__tab {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: auto;
        height: rem(48px);
        text-align: center;
        font-size: rem(18px);
        line-height: 1;
        border: rem(2px) solid #9fa6a9;
        border-radius: rem(15px);
        color: #9fa6a9;
        transition: 0.4s;
        cursor: pointer;
        &.active,
        &:hover {
          background: $orange;
          color: #fff;
          border-color: $orange;
        }
      }
    }
    &-list {
      max-height: rem(730px);
      gap: 0 rem(20px);
      flex-wrap: wrap;
      &__item {
        width: 30%;
      }
    }
  }
}
</style>
