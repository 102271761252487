<template>
  <section class="steps section">
    <div class="container">
      <div class="steps__title section-title">Как <br />участвовать</div>
    </div>
    <div class="steps-bg-elements">
      <div class="steps-bg-elements__item">
        <svg
          width="7"
          height="7"
          viewBox="0 0 7 7"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.9"
            d="M1.0528 2.63016C1.5157 1.0386 3.18075 0.124344 4.77085 0.586328C6.3622 1.04868 7.27744 2.71261 6.81454 4.30418C6.35164 5.89574 4.68655 6.80994 3.0952 6.3476C1.5051 5.88561 0.589894 4.22173 1.0528 2.63016Z"
            fill="#D20C1C"
          />
        </svg>
      </div>
      <div class="steps-bg-elements__item">
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19.6015 0.776241L22.9219 11.898L31.7189 19.4741L20.5973 22.7923L13.0224 31.5848L9.70186 20.463L0.907796 12.8893L12.0294 9.57116L19.6015 0.776241Z"
            fill="#71C1DF"
          />
        </svg>
      </div>
      <div class="steps-bg-elements__item _no-animate">
        <svg
          width="35"
          height="35"
          viewBox="0 0 35 35"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M34.1629 8.4125C34.8727 12.9057 33.4289 18.1875 31.417 22.9461C29.4033 27.706 26.8196 31.944 25.24 34.3481C25.159 34.4707 25.04 34.5537 24.9094 34.5946C24.7769 34.6368 24.6329 34.6369 24.4948 34.5843C19.1455 32.4814 5.42606 27.6278 1.38187 19.4732C0.38433 17.464 -0.0145532 15.4919 0.197544 13.6154L0.204395 13.5566C0.592751 9.81613 2.3929 7.14302 6.00912 5.50707C9.29272 4.02015 14.0991 4.09435 17.3263 7.65764C17.9653 2.96443 20.0861 1.32404 23.5298 0.415863C25.7698 -0.177212 29.2608 0.11174 31.7262 2.89197L31.7533 2.92828C33.3023 4.65354 33.9181 6.85962 34.1629 8.4125Z"
            fill="#D00015"
          />
        </svg>
      </div>
      <div class="steps-bg-elements__item">
        <svg
          width="7"
          height="7"
          viewBox="0 0 7 7"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.9"
            d="M1.0528 2.63016C1.5157 1.0386 3.18075 0.124344 4.77085 0.586328C6.3622 1.04868 7.27744 2.71261 6.81454 4.30418C6.35164 5.89574 4.68655 6.80994 3.0952 6.3476C1.5051 5.88561 0.589894 4.22173 1.0528 2.63016Z"
            fill="#EFA800"
          />
        </svg>
      </div>
      <div class="steps-bg-elements__item">
        <svg
          width="44"
          height="44"
          viewBox="0 0 44 44"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.0314 0.157227L27.6988 12.4263L43.3113 17.0284L31.026 27.6495L26.4337 43.2107L15.7659 30.9417L0.15869 26.3409L12.444 15.7199L17.0314 0.157227Z"
            fill="#DA0D22"
          />
        </svg>
      </div>

      <div class="steps-bg-elements__item">
        <svg
          width="24"
          height="23"
          viewBox="0 0 24 23"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.2147 22.8969L8.80905 16.3165L0.701981 13.6784L7.28998 8.29665L9.9224 0.215604L15.3282 6.79599L23.4326 9.43331L16.8446 14.815L14.2147 22.8969Z"
            fill="#18B6F0"
          />
        </svg>
      </div>
    </div>
    <svg
      class="steps__path"
      width="1318"
      height="594"
      viewBox="0 0 1318 594"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        class="path"
        d="M3 525.135C71.422 443.534 270.912 276.529 521.494 261.322C772.076 246.114 727.841 361.623 674.401 421.279C653.704 424.524 619.354 418.219 647.526 367.032C675.698 315.846 812.171 305.213 876.887 306.295C1006.16 301.195 1176.74 521.109 1029.79 584.945C934.806 626.209 876.887 512.512 908.395 385.114C927.917 306.18 1073.81 165.348 1115.51 134.284C1148.88 109.432 1295.76 14.6638 1318 4"
        stroke="black"
        stroke-width="3.44122"
      />
      <path
        d="M3 525.135C71.422 443.534 270.912 276.529 521.494 261.322C772.076 246.114 727.841 361.623 674.401 421.279C653.704 424.524 619.354 418.219 647.526 367.032C675.698 315.846 812.171 305.213 876.887 306.295C1006.16 301.195 1176.74 521.109 1029.79 584.945C934.806 626.209 876.887 512.512 908.395 385.114C927.917 306.18 1073.81 165.348 1115.51 134.284C1148.88 109.432 1295.76 14.6638 1318 4"
        stroke="#FFF7DD"
        stroke-width="7.44"
        stroke-dasharray="13.6 17.21"
      />
    </svg>

    <div class="steps__content">
      <div class="container">
        <div class="steps__list">
          <div class="steps__item">
            <div class="steps__item-content">
              <img
                src="../assets/images/steps/bbl-1.png"
                alt=""
                class="steps__item-bg"
              />
              <div class="steps__item-text">
                Купите любые продукты
                <img src="../assets/images/steps/logo.png" alt="" />
                с&nbsp;картой Магнит на&nbsp;сумму от 150&nbsp;рублей
              </div>
            </div>
            <img
              src="../assets/images/steps/product.png"
              alt=""
              class="steps__item-img"
            />
          </div>
          <div class="steps__item">
            <Sun />
            <img
              src="../assets/images/steps/check.png"
              alt=""
              class="steps__item-img"
            />
            <div class="steps__item-content">
              <img
                src="../assets/images/steps/bbl-2.png"
                alt=""
                class="steps__item-bg"
              />
              <div class="steps__item-text">
                <span>Загрузите чек на&nbsp;сайт</span>
                <!-- <button
                  @click="
                    toCheckUpload({
                      site_section: 'main',
                      action: 'check_download',
                      eventContext: 'how_to',
                    })
                  "
                  class="btn btn--white"
                >
                  Загрузить чек
                </button> -->
              </div>
            </div>
          </div>
          <div class="steps__item">
            <div class="steps__item-content">
              <img
                src="../assets/images/steps/bbl-3.png"
                alt=""
                class="steps__item-bg"
              />
              <div class="steps__item-text">
                Загружайте больше чеков для увеличения шансов выиграть приз!
              </div>
            </div>
            <img
              src="../assets/images/steps/gift.png"
              alt=""
              class="steps__item-img"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Sun from "../components/AppSun.vue";
export default {
  components: { Sun },
  data: function () {
    return {
      publicPath: process.env.BASE_URL,
      settings: {
        arrows: true,
        dots: true,
        slidesToShow: 3,
        centerPadding: "0%",
        infinite: true,
        centerMode: true,
        fade: false,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 3000,
        pauseOnHover: false,
      },
      steps: [
        {
          title: "КУПИ",
          text: "SNICKERS",
        },
        {
          title: "РЕГИСТРИРУЙ код",
          text: "НА САЙТЕ ИЛИ В&nbsp;ТЕЛЕГРАМЕ",
        },
        {
          title: "ПОЛУЧИ",
          text: "ГАРАНТИРОВАННЫЙ ПРИЗ",
        },
        {
          title: "УЧАСТВУЙ В&nbsp;РОЗЫГРЫШЕ",
          text: "ИМЕННЫХ ПРИЗОВ",
        },
      ],
    };
  },
  methods: {
    showModal(name) {
      this.$modal.show(name);
    },
  },
  mounted() {},
};
</script>

<style lang="scss">
.steps {
  position: relative;
  display: flex;
  height: auto;
  flex-direction: column;
  // align-items: center;
  min-height: 100vh;
  padding-bottom: rem(23px);
  padding-top: 0 !important;
  // max-height: 100vh !important;
  overflow: hidden;
  z-index: 2;

  .container {
    z-index: 99;
  }
  &-bg-elements {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 10;
    pointer-events: none;
    &__item {
      position: absolute;
      display: block;
      transform: scale(0.7);
      animation: rotareStar 4s linear infinite forwards normal;
      svg {
        animation: blinkSmooth 2s infinite forwards alternate;
      }
      &._no-animate {
        animation: none;
      }
      &:nth-child(1) {
        top: 48%;
        left: 60%;
      }
      &:nth-child(2) {
        top: 43%;
        left: 35%;
      }
      &:nth-child(3) {
        top: 80%;
        left: 62%;
      }
      &:nth-child(4) {
        top: 35%;
        left: 44%;
      }
      &:nth-child(5) {
        top: 58%;
        left: 20%;
        @media (min-width: $md) {
          left: 93%;
        }
      }
      &:nth-child(6) {
        top: 81%;
        left: 91%;
      }

      &:nth-child(2n) {
        animation-delay: 0.5s;
        svg {
          animation-delay: 0.5s;
        }
      }
      &:nth-child(3n) {
        animation-delay: 0.7s;
        svg {
          animation-delay: 0.7s;
        }
      }
      &:nth-child(4n) {
        animation-delay: 1s;
        svg {
          animation-delay: 1s;
        }
      }
    }
  }
  &__title.section-title {
    margin-bottom: rem(40px);
  }
  &__content {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__list {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  &__item {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    margin-right: rem(5px);
    &:not(:last-of-type) {
      margin-bottom: rem(0px);
    }
    &:nth-child(1) {
      margin-bottom: rem(30px);
      .steps__item-content {
      }
      .steps__item-bg {
        width: rem(309px);
      }
      .steps__item-img {
        width: rem(310px);
        margin-top: rem(-40px);
        margin-left: rem(20px);
      }
      .steps__item-text {
        top: rem(70px);
        left: rem(60px);
      }
    }
    &:nth-child(2) {
      margin-top: auto;
      margin-right: auto;
      margin-bottom: rem(45px);
      transition-delay: 1.5s;
      .steps__item-bg {
        width: rem(335px);
      }
      .steps__item-img {
        order: 1;
        width: rem(235px);
        margin-top: rem(-55px);
        margin-left: 20%;
        z-index: 1;
      }
      .steps__item-text {
        top: rem(87px);
        left: rem(64px);
      }
    }
    &:nth-child(3) {
      transition-delay: 3s;
      .steps__item-content {
      }
      .steps__item-bg {
        width: rem(346px);
        margin-left: rem(20px);
        max-width: none;
      }
      .steps__item-img {
        width: rem(195px);
        margin-left: rem(45px);
        margin-top: rem(-40px);
      }
      .steps__item-text {
        top: rem(105px);
        left: rem(90px);
        width: rem(220px);
        transform: rotate(-5deg);
      }
    }
    &-content {
      position: relative;
    }
    &-text {
      position: absolute;
      top: rem(70px);
      left: rem(68px);
      width: rem(200px);
      color: #fff;
      font-size: rem(20px);
      line-height: rem(21px);
      img {
        display: inline;
        height: rem(22px);
      }
      .btn {
        margin-top: rem(15px);
        font-size: rem(20px);
        color: $orange_light;
      }
    }
    .sun {
      position: absolute;
      top: rem(280px);
      right: rem(40px);
      width: rem(130px);
      height: rem(130px);
      z-index: 10;
    }
  }

  &__item-title {
    font-size: rem(14px);
    line-height: 1;
    color: $brown;
    text-transform: uppercase;
  }
  &__item-descr {
    margin: 0 auto;
    font-size: rem(14px);
    line-height: rem(16px);
    color: #fff;
    a {
      border-bottom: 1px solid currentColor;
    }
  }
  &__rules {
    margin: rem(0px) auto rem(35px);
    font-size: rem(14px);
    line-height: 1;
  }
  &__path {
    position: absolute;
    width: auto;
    height: 150vw;
    top: 30%;
    left: -90%;
    transform: rotate(75deg);
    .path {
      stroke-dasharray: 3000;
      stroke-dashoffset: 3000;
      .active & {
        animation: dash 6s linear forwards;
      }
    }
  }
  @media (min-width: $md) {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: rem(100px) !important;
    padding-bottom: 0;
    margin-bottom: rem(-20px);
    &::before {
      background-size: contain;
    }
    .container {
    }

    &__list {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: row;
      margin-top: rem(-105px);
    }

    &__item {
      min-width: 20%;
      flex: 0 0 auto;
      margin: 0;
      height: auto;
      margin-right: rem(5px);
      opacity: 0;
      transition: 0.4s;
      .active & {
        opacity: 1;
      }
      &:not(:last-of-type) {
        margin-bottom: rem(0px);
      }
      &:nth-child(1) {
        margin-right: rem(15px);
        margin-bottom: 0;
        .steps__item-content {
          margin-left: rem(40px);
        }
        .steps__item-bg {
          width: rem(309px);
        }
        .steps__item-img {
          width: rem(400px);
          margin-top: rem(-10px);
          margin-left: 0;
        }
        .steps__item-text {
          top: rem(70px);
          left: rem(60px);
        }
      }
      &:nth-child(2) {
        margin-top: auto;
        margin-right: auto;
        margin-bottom: rem(25px);
        transition-delay: 1.5s;
        .steps__item-bg {
          width: rem(335px);
        }
        .steps__item-img {
          order: 0;
          width: rem(340px);
          margin-left: 0;
          margin-top: 0;
          margin-bottom: rem(-60px);
        }
        .steps__item-text {
          top: rem(87px);
          left: rem(64px);
        }
      }
      &:nth-child(3) {
        margin-top: rem(55px);
        transition-delay: 3s;
        .steps__item-content {
          left: rem(30px);
        }
        .steps__item-bg {
          width: rem(356px);
        }
        .steps__item-img {
          width: rem(253px);
          margin-left: rem(33px);
          margin-top: rem(-45px);
        }
        .steps__item-text {
          top: rem(110px);
          left: rem(80px);
          width: rem(220px);
          transform: rotate(-5deg);
        }
      }
      .sun {
        top: rem(80px);
        right: rem(20px);
        width: rem(180px);
        height: rem(180px);
      }
    }
    &__item-title {
      font-size: rem(20px);
      line-height: rem(22px);
    }
    &__path {
      position: absolute;
      width: 103vw;
      height: auto;
      top: auto;
      bottom: rem(60px);
      left: rem(-30px);
      transform: rotate(0deg);
    }
  }
}
@keyframes dash {
  from {
    stroke-dashoffset: 3000;
  }
  to {
    stroke-dashoffset: 0;
  }
}
</style>
