<template>
  <div class="form-page-form__block">
    <inputText
      label="Имя"
      placeholder="Ваше имя"
      :field="name"
      name="name"
      autocomplete="name"
      :class="{
        error: validationStatus($v.name) || errorMessage.name,
      }"
      :error="
        (!$v.name.required && $v.name.$error
          ? 'Обязательное поле'
          : !$v.name.rus && $v.name.$error
          ? 'Введите текст на русском языке'
          : '') || errorMessage.name
      "
      @input="name = $event"
    />
    <inputText
      label="Email*"
      placeholder="example@yandex.ru"
      :field="email"
      type="email"
      :class="{
        error: validationStatus($v.email) || errorMessage.email,
      }"
      :error="
        (!$v.email.required && $v.email.$error
          ? 'Обязательное поле'
          : !$v.email.email && $v.email.$error
          ? 'Некорректный e-mail'
          : '') || errorMessage.email
      "
      @input="email = $event"
    />
    <inputText
      label="Номер карты лояльности"
      placeholder="1234 4567 8910 1112"
      :mask="'#### #### #### ####'"
      :field="card"
      name="card"
      :class="{
        error: validationStatus($v.card) || errorMessage.card,
      }"
      :error="
        (!$v.card.required && $v.card.$error
          ? 'Обязательное поле'
          : !$v.card.minLength && $v.card.$error
          ? 'Минимум ' + $v.card.$params.minLength.min + ' символов'
          : '') || errorMessage.card
      "
      @input="card = $event"
    />
    <div class="form-page-form__checkboxes">
      <checkBox
        :label="`Подтверждаю, что мне больше 18 лет`"
        :field="checkbox3"
        :class="{
          error: validationStatus($v.checkbox3) || errorMessage.checkbox3,
        }"
        :error="errorMessage.checkbox3"
        @input="checkbox3 = $event"
      />
      <checkBox
        :label="`Соглашаюсь с <a href='${publicPath}docs/rules.pdf' class='_undeline-link _bold' target='_blank'>Правилами участия</a> и <a href='${publicPath}docs/agreement.docx' class='_undeline-link _bold' target='_blank'> обработкой персональных данных</a>`"
        :field="checkbox"
        :class="{
          error: validationStatus($v.checkbox) || errorMessage.checkbox,
        }"
        :error="errorMessage.checkbox"
        @input="checkbox = $event"
      />
      <checkBox
        :label="`Согласен получать рекламные сообщения от Kinder<sup>®</sup>`"
        :field="checkbox2"
        :class="{
          error: validationStatus($v.checkbox2) || errorMessage.checkbox2,
        }"
        :error="errorMessage.checkbox2"
        @input="checkbox2 = $event"
      />
    </div>
    <div class="form-page-form__footer">
      <button
        type="button"
        class="btn btn--primary"
        @click="submit()"
        :class="{ loading: submitStatus == 'PENDING' }"
      >
        Зарегистрироваться<img
          src="../../assets/img/spinner.svg"
          class="loader"
          alt=""
        />
      </button>
      <div class="form-page__another-option">
        <p>
          Вы уже зарегистрированы?
          <router-link :to="{ name: 'Login' }" class="_undeline-link"
            >Войти</router-link
          >
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import inputText from "@/components/form/inputText.vue";
import checkBox from "@/components/form/checkBox.vue";

import { required, email, minLength, sameAs } from "vuelidate/lib/validators";
const rus = (value) => /^[А-ЯЁа-яё ]+$/.test(value);

function clear(o = {}) {
  Object.keys(o).forEach((i) => {
    if (o[i] === 0) {
      delete o[i];
    }
  });
}

export default {
  name: "SignupForm",
  props: ["network"],
  data() {
    return {
      publicPath: "/",
      ready: false,
      name: null,
      email: null,
      card: null,
      checkbox: false,
      checkbox2: false,
      checkbox3: false,
      errorMessage: {
        name: null,
        email: null,
        card: null,
        checkbox: null,
        checkbox2: null,
        checkbox3: null,
      },

      submitStatus: null,
    };
  },

  validations: {
    email: { required, email },
    name: { required, rus },
    card: { required, minLength: minLength(16) },
    // password: {
    //   required,
    //   minLength: minLength(6),
    //   maxLength: maxLength(24),
    // },
    // repPassword: {
    //   required,
    //   minLength: minLength(6),
    //   maxLength: maxLength(24),
    //   sameAsPassword: sameAs('password'),
    // },
    checkbox: {
      required,
      sameAs: sameAs(() => true),
    },
    checkbox3: {
      required,
      sameAs: sameAs(() => true),
    },
  },
  watch: {
    checkbox(newVal) {
      if (!newVal) {
        this.$store.commit("SET_AGREE", false);
      }
    },
  },
  methods: {
    selectSuggestion(suggestion) {
      this.city = suggestion.data.city;
    },
    errorReset() {
      this.$v.$reset();

      this.errorMessage = {
        name: null,
        email: null,
        card: null,
        checkbox: null,
        checkbox2: null,
        checkbox3: null,
      };
    },
    pushTo(link) {
      window.open(link, "_blank").focus();
    },
    validationStatus: function (validation) {
      return typeof validation != "undefined" ? validation.$error : false;
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$anyError) {
        const errors = Object.keys(this.errorMessage);
        for (let index = 0; index < errors.length; index++) {
          const element = errors[index];
          if (this.$v[element].$error) {
            this.gtmEvent(element, 'registration', 'step_error')
          }
        }
      }
      if (this.$v.$pendding || this.$v.$error) return;

      if (this.submitStatus !== "PENDING") {
        this.gtmEvent("", "registration", "step1_success", "step_1");
        this.submitStatus = "PENDING";
        const formData = {
          name: this.name,
          login: this.email,
          card: this.card,
          agree: this.checkbox ? 1 : 0,
          mailing: this.checkbox2 ? 1 : 0,
          retailer: 2,
        };
        clear(formData);
        const data = {
          _member_email: this.email,
          q474: this.name,
          q786: this.checkbox3 ? 1 : 0,
          q517: this.checkbox ? 1 : 0,
          q772: this.checkbox2 ? 1 : 0,
        };
        clear(data);
        fetch("https://sendsay.ru/form/kinder/14/", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        })
          .then((data) => data.json())
          .then((res) => {
            if (res.errors) {
              this.submitStatus = null;
              this.$modal.show("common_error", {
                title: res.errors,
                errors: res.errors || [],
              });
            } else {
              this.$store
                .dispatch("SignUp", formData)
                .then((r) => {
                  console.log(r);
                  if (!r.result) {
                    this.submitStatus = null;

                    let fieldsError = null;

                    if (r.error && r.error.email) {
                      [this.errorMessage.email] = r.error.email || "";
                      this.gtmEvent('email', 'registration', 'step_error');
                      fieldsError = true;
                    } else if (r.error && r.error.login) {
                      [this.errorMessage.email] = r.error.login;
                      this.gtmEvent('email', 'registration', 'step_error');
                      fieldsError = true;
                    }
                    if (r.error && r.error.card) {
                      [this.errorMessage.card] = r.error.card;
                      this.gtmEvent('card', 'registration', 'step_error');
                      fieldsError = true;
                    }
                    if (r.error && r.error.name) {
                      [this.errorMessage.name] = r.error.name;
                      this.gtmEvent('name', 'registration', 'step_error');
                      fieldsError = true;
                    }
                    if (r.error && r.error.mailing) {
                      [this.errorMessage.checkbox2] = r.error.mailing;
                      this.gtmEvent('mailing', 'registration', 'step_error');
                      fieldsError = true;
                    }
                    if (r.error && r.error.agree) {
                      [this.errorMessage.checkbox] = r.error.agree;
                      this.gtmEvent('agree', 'registration', 'step_error')
                      fieldsError = true;
                    }

                    if (r.error && !fieldsError) {
                      this.$modal.show("common_error", {
                        title: r.error,
                      });
                    }
                  } else {
                    this.$router.push({ name: "FinishSignup" });
                  }
                })
                .catch((e) => {
                  this.submitStatus = null;
                  console.log(e);
                });
            }
          })
          .catch((e) => {
            this.submitStatus = null;
            this.$modal.show("common_error", {
              title: e,
            });
          });
      }
    },
    showLogInModal() {
      this.$modal.hide("signup");
      this.$modal.show("authorization");
    },
  },
  mounted() {
    console.log(this.$route);
  },
  components: {
    inputText,
    checkBox,
  },
};
</script>

<style lang="scss">
.signup {
  &__row {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &__checkbox_list {
    margin-top: 0;
    .form-checkbox {
      margin-bottom: rem(10px);
    }
  }
  &__footer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &-social {
    text-align: center;
    margin-bottom: rem(16px);
    &__title {
      margin-bottom: rem(12px);
      font-size: rem(14px);
      line-height: rem(16px);
      // font-family: "Roboto-Bold";
      font-weight: 900;
      text-transform: uppercase;
      letter-spacing: -0.01em;
      color: $brown;
    }
    &__text {
      margin-top: rem(15px);
      font-size: rem(18px);
      line-height: rem(22px);
    }
    &-list {
      display: flex;
      justify-content: center;
      margin-bottom: rem(24px);
    }
    &-item {
      width: rem(64px);
      margin: 0 rem(-3px);
      transition: 0.4s;
      @media (min-width: $sm) {
        width: rem(61px);
      }
    }
  }
  &__text {
    margin-bottom: rem(14px);
    font-size: rem(13px);
    line-height: 1;
    color: $brown;
  }
  &__footer {
    .btn {
      width: 100%;
    }
  }
  @media (min-width: $sm) {
    width: rem(380px);
    margin: auto;
    &__row {
      flex-direction: row;
    }
    &__footer {
      flex-direction: column;
      justify-content: center;
      .btn {
        min-width: auto;
        margin: 0;
        &:not(:last-of-type) {
          margin-bottom: rem(24px);
        }
      }
    }
    &__checkbox_list {
      margin-bottom: 0;
      .form-checkbox {
        margin-bottom: rem(14px);
      }
    }
    &-social {
      margin-bottom: rem(16px);
      &-list {
        margin-bottom: rem(30px);
      }
      &-item {
        width: rem(93px);
      }
      &__title {
        margin-bottom: rem(20px);
        font-size: rem(22px);
        line-height: rem(25px);
      }
      &__text {
        margin-top: rem(15px);
        font-size: rem(32px);
        line-height: rem(38px);
      }
    }
  }
}
</style>
